import { BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

export interface AcceptNonInstantPayContentProps {
  onAccept: () => void;
  onCancel: () => void;
}

export function AcceptNonInstantPayContent(props: AcceptNonInstantPayContentProps) {
  const { onAccept, onCancel } = props;

  return (
    <>
      <BottomSheetIllustrationContent
        illustrationType="non-instant-pay"
        title="Accept non InstantPay?"
        description="By proceeding, this shift will no longer be eligible for InstantPay. You'll need to submit a paper timesheet, and payment may take up to two business days to process."
      />
      <DialogFooter
        orientation="vertical"
        sx={{ marginTop: 10 }}
        closeButtonAriaLabel="Cancel"
        onClose={() => {
          onCancel();
        }}
      >
        <Button
          fullWidth
          variant="destructive"
          size="large"
          onClick={() => {
            onAccept();
          }}
        >
          Accept non-InstantPay
        </Button>
      </DialogFooter>
    </>
  );
}
