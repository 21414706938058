import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { startNFCScan } from "@src/appV2/Nfc/utils/nfcReader";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";

const NFC_SCAN_TIMEOUT_IN_MS = 20_000;

export function useNfcHashReader(
  options: UseMutationOptions<string, void> = {}
): UseMutationResult<string, void, void> {
  return useMutation({
    mutationFn: async () => {
      const nfcScanner = startNFCScan();
      return await nfcScanner.readOne(NFC_SCAN_TIMEOUT_IN_MS);
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.NFC_HASH_READ_FAILED,
    },
    ...options,
  });
}
