import { BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { LoadingButton } from "@src/appV2/redesign/components/LoadingButton";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

export interface TooFarAwayPopupProps {
  workplaceName: string;

  onTryAgain: () => Promise<void>;
  onSkipLocationCheck: () => void;
  onCancel: () => void;
}

const MIN_LOADER_DURATION_IN_MS = 2000;

export function TooFarAwayPopup(props: TooFarAwayPopupProps) {
  const { workplaceName, onTryAgain: onTryAgainProperty, onSkipLocationCheck, onCancel } = props;

  const timerRef = useRef<NodeJS.Timeout>();

  const { isLoading: isGeofenceCheckLoading, mutateAsync: onTryAgain } = useMutation({
    mutationFn: async () => {
      const now = Date.now();

      await onTryAgainProperty();

      /**
       * We want to have a minimum loader duration so
       * HCP can see that the action was executed,
       * even when it lasted for a short time.
       */
      const timeElapsedInMs = Date.now() - now;
      if (timeElapsedInMs < MIN_LOADER_DURATION_IN_MS) {
        await new Promise((resolve) => {
          timerRef.current = setTimeout(resolve, MIN_LOADER_DURATION_IN_MS - timeElapsedInMs);
        });
      }
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <>
      <BottomSheetIllustrationContent
        illustrationType="location"
        title="Too far away"
        description={`It looks like you are not inside ${workplaceName}. Please try again when you are inside the facility. `}
      />
      <DialogFooter
        orientation="vertical"
        sx={{ marginTop: 10 }}
        closeButtonAriaLabel="Cancel"
        onClose={() => {
          onCancel();
        }}
      >
        <LoadingButton
          fullWidth
          isLoading={isGeofenceCheckLoading}
          variant="contained"
          size="large"
          onClick={async () => {
            await onTryAgain();
          }}
        >
          I&apos;m inside the facility — Try again
        </LoadingButton>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          onClick={() => {
            onSkipLocationCheck();
          }}
        >
          Skip location confirmation
        </Button>
      </DialogFooter>
    </>
  );
}
