import { BottomSheetLoadingContent } from "@clipboard-health/ui-components";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

export interface DisableShiftNfcFailedProps {
  onTryAgain: () => void;
  onClose: () => void;
}

export function DisableShiftNfcFailed(props: DisableShiftNfcFailedProps) {
  const { onTryAgain, onClose } = props;

  return (
    <>
      <BottomSheetLoadingContent
        title="Clock in failed"
        description="Something went wrong while clocking in. Please try again."
      />
      <DialogFooter
        orientation="vertical"
        onClose={() => {
          onClose();
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            onTryAgain();
          }}
        >
          Try again
        </Button>
      </DialogFooter>
    </>
  );
}
