import { BottomSheetLoadingContent } from "@clipboard-health/ui-components";
import type { GeoLocation } from "@src/appV2/Location";
import { useLazyIsInsideGeofence } from "@src/appV2/Location/hooks/useIsInsideGeofence";
import { useState } from "react";

import { AcceptNonInstantPayContent } from "./AcceptNonInstantPayContent";
import { LocationPermissionsContent } from "./LocationPermissionsContent";
import { TooFarAwayPopup } from "./TooFarAwayPopup";

export interface WorkerGeofenceCheckProps {
  action: "clock-in" | "clock-out";
  shiftId: string;
  workerId: string;
  workplaceName: string;
  workplaceLocation: GeoLocation;
  onSuccess: () => void;
  onSkipLocationCheck: () => void;
  onCancel: () => void;
}

type WorkerGeofenceCheckStep =
  | "location-permissions"
  | "fetching-geofence-status"
  | "accept-non-instant-pay"
  | "too-far-away";

const WORKPLACE_DEFAULT_GEOFENCE_DISTANCE_IN_MILES = 0.75;

export function WorkerGeofenceCheck(props: WorkerGeofenceCheckProps) {
  const {
    shiftId,
    workerId,
    workplaceName,
    workplaceLocation,
    action,
    onSuccess,
    onSkipLocationCheck,
    onCancel,
  } = props;

  const [content, setContent] = useState<WorkerGeofenceCheckStep>("location-permissions");

  const getIsInsideGeofence = useLazyIsInsideGeofence({
    requestUniqueId: `${shiftId}-${workerId}`,
    action,
    fallbackGeofenceDistanceInMiles: WORKPLACE_DEFAULT_GEOFENCE_DISTANCE_IN_MILES,
    fallbackGeofenceLocation: workplaceLocation,
  });

  async function resolveGeofenceStatus() {
    try {
      const result = await getIsInsideGeofence();

      if (result.data?.isInsideGeofence) {
        onSuccess();
      } else {
        setContent("too-far-away");
      }
    } catch {
      setContent("too-far-away");
    }
  }

  return (
    <>
      {content === "location-permissions" && (
        <LocationPermissionsContent
          onPermissionsGranted={async () => {
            setContent("fetching-geofence-status");
            await resolveGeofenceStatus();
          }}
          onSkipLocationCheck={() => {
            setContent("accept-non-instant-pay");
          }}
        />
      )}

      {content === "fetching-geofence-status" && (
        <BottomSheetLoadingContent
          title="Confirming your location"
          description="This can take up to 10 seconds"
        />
      )}

      {content === "accept-non-instant-pay" && (
        <AcceptNonInstantPayContent
          onAccept={() => {
            onSkipLocationCheck();
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      )}

      {content === "too-far-away" && (
        <TooFarAwayPopup
          workplaceName={workplaceName}
          onTryAgain={async () => {
            await resolveGeofenceStatus();
          }}
          onSkipLocationCheck={() => {
            setContent("accept-non-instant-pay");
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      )}
    </>
  );
}
