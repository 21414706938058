import { BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { isCapacitorPlatform } from "@src/appV2/lib/utils";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { useEffect } from "react";

export interface LocationPermissionsContentProps {
  onPermissionsGranted: () => void;
  onSkipLocationCheck: () => void;
}

export function LocationPermissionsContent(props: LocationPermissionsContentProps) {
  const { onPermissionsGranted, onSkipLocationCheck } = props;

  const {
    data: isLocationPermissionGranted,
    isSuccess: isPermissionRequestSuccess,
    refetch: refetchIsDeviceLocationPermissionGranted,
  } = useIsDeviceLocationPermissionGranted();

  const shouldAskForLocationPermissions =
    isPermissionRequestSuccess && isLocationPermissionGranted === false && isCapacitorPlatform();

  useEffect(() => {
    if (!shouldAskForLocationPermissions) {
      onPermissionsGranted();
    }
  }, [onPermissionsGranted, shouldAskForLocationPermissions]);

  return shouldAskForLocationPermissions ? (
    <>
      <BottomSheetIllustrationContent
        title="Location access"
        description="Please, grant location access in your device settings for Clipboard Health application to verify you’re at the facility."
        illustrationType="location"
      />
      <DialogFooter orientation="vertical" sx={{ marginTop: 10 }}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={async () => {
            await refetchIsDeviceLocationPermissionGranted();
          }}
        >
          I&apos;ve granted access
        </Button>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          onClick={() => {
            onSkipLocationCheck();
          }}
        >
          Skip location confirmation
        </Button>
      </DialogFooter>
    </>
  ) : null;
}
