import { BottomSheet, BottomSheetLoadingContent } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useToast } from "@src/appV2/lib";
import { type GeoLocation } from "@src/appV2/Location/types";
import { BreakConfirmationBottomSheet } from "@src/appV2/redesign/Shift/BreakConfirmationBottomSheet/BreakConfirmationBottomSheet";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { type NfcTagRequest } from "@src/appV2/Shifts/Shift/types";
import { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { ShiftMarkedNonIpReasons, useMarkShiftNonInstant } from "../../api/useMarkShiftNonInstant";
import {
  ActionCheckType,
  ShiftStage,
  useRecordShiftTimekeepingAction,
} from "../../api/useRecordShiftTimekeepingAction";
import { NfcHashValidationAction } from "../NfcValidation/api/types";
import { useDisableShiftNfcCheck } from "../NfcValidation/api/useDisableShiftNfcCheck";
import { WorkerNfcValidation } from "../NfcValidation/WorkerNfcValidation";
import { DisableShiftNfcFailed } from "./DisableShiftNfcFailed";
import { WorkerGeofenceCheck } from "./WorkerGeofenceCheck";

export interface ClockInBottomSheetProps {
  modalState: UseModalState;
  shiftId: string;
  workerId: string;
  workplaceId: string;
  workplaceName: string;
  workplaceLocation: GeoLocation;
  isNfcClockInEnabled: boolean;
  nfcTagRequests: NfcTagRequest[];
  breakPolicyNoteId: string;
  requiresBreakPolicyAcknowledgement: boolean;
}

type ClockInBottomSheetStep =
  | "location-check"
  | "requesting-clock-in"
  | "requesting-non-instant-pay"
  | "disable-shift-nfc-check-failed";

export function ClockInBottomSheet(props: ClockInBottomSheetProps) {
  const {
    modalState,
    shiftId,
    workerId,
    workplaceId,
    workplaceName,
    workplaceLocation,
    isNfcClockInEnabled: isNfcClockInEnabledProperty,
    nfcTagRequests,
    breakPolicyNoteId,
    requiresBreakPolicyAcknowledgement: requiresBreakPolicyAcknowledgementProperty,
  } = props;

  const history = useHistory();
  const [step, setStep] = useState<ClockInBottomSheetStep>("location-check");

  const { mutateAsync: recordTimekeepingAction } = useRecordShiftTimekeepingAction();
  const { mutateAsync: markNonInstant } = useMarkShiftNonInstant();
  const { mutateAsync: callDisableShiftNfcCheck } = useDisableShiftNfcCheck();

  const { showErrorToast } = useToast();

  const [requiresBreakPolicyAcknowledgement, setRequiresBreakPolicyAcknowledgement] = useState(
    requiresBreakPolicyAcknowledgementProperty
  );
  const [isNfcClockInEnabled, setIsNfcClockInEnabled] = useState(isNfcClockInEnabledProperty);

  const shiftDetailsPath = generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
    shiftId,
  });

  function closeBottomSheet() {
    setStep("location-check");
    modalState.closeModal();
  }

  async function recordClockIn() {
    try {
      await recordTimekeepingAction({
        shiftId,
        stage: ShiftStage.CLOCK_IN,
        shiftActionCheck: isNfcClockInEnabled ? ActionCheckType.NFC : ActionCheckType.LOCATION,
      });

      history.replace(shiftDetailsPath);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "Failed to clock in, please try again.";
      showErrorToast(errorMessage);

      closeBottomSheet();
    }
  }

  async function convertToNonInstantPay() {
    try {
      await markNonInstant({
        shiftId,
        reason: isNfcClockInEnabled
          ? ShiftMarkedNonIpReasons.NFC_FAILURE
          : ShiftMarkedNonIpReasons.NO_LOCATION,
      });

      history.replace(shiftDetailsPath);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to convert to non-instant pay, please try again.";
      showErrorToast(errorMessage);

      closeBottomSheet();
    }
  }

  async function disableShiftNfcCheck() {
    setStep("requesting-clock-in");

    try {
      await callDisableShiftNfcCheck({ shiftId, workerId });
      setIsNfcClockInEnabled(false);
    } catch {
      setStep("disable-shift-nfc-check-failed");
      return;
    }

    setStep("location-check");
  }

  return (
    <>
      {requiresBreakPolicyAcknowledgement && (
        <BreakConfirmationBottomSheet
          action={NoteAcknowledgementAction.CLOCK_IN}
          isLoading={false}
          modalState={modalState}
          shiftId={shiftId}
          workplaceId={workplaceId}
          breakPolicyNoteId={breakPolicyNoteId}
          onAcknowledge={async () => {
            setRequiresBreakPolicyAcknowledgement(false);
          }}
        />
      )}

      {!requiresBreakPolicyAcknowledgement && (
        <BottomSheet modalState={modalState} canDismiss={false}>
          {step === "location-check" && !isNfcClockInEnabled && (
            <WorkerGeofenceCheck
              action="clock-in"
              shiftId={shiftId}
              workerId={workerId}
              workplaceName={workplaceName}
              workplaceLocation={workplaceLocation}
              onSuccess={async () => {
                setStep("requesting-clock-in");
                await recordClockIn();
              }}
              onSkipLocationCheck={async () => {
                setStep("requesting-non-instant-pay");
                await convertToNonInstantPay();
              }}
              onCancel={() => {
                closeBottomSheet();
              }}
            />
          )}
          {step === "location-check" && isNfcClockInEnabled && (
            <WorkerNfcValidation
              shiftId={shiftId}
              metaProps={{ workerId, workplaceId, workplaceName }}
              nfcTagRequests={nfcTagRequests}
              clockAction={NfcHashValidationAction.CLOCK_IN}
              onSuccess={async () => {
                setStep("requesting-clock-in");
                await recordClockIn();
              }}
              onSkipLocationCheck={async () => {
                setStep("requesting-non-instant-pay");
                await convertToNonInstantPay();
              }}
              onCancel={() => {
                closeBottomSheet();
              }}
              onNfcNotSupported={async () => {
                await disableShiftNfcCheck();
              }}
            />
          )}
          {step === "requesting-clock-in" && (
            <BottomSheetLoadingContent
              title="Confirming your clock in"
              description="Should only take a few seconds"
            />
          )}
          {step === "requesting-non-instant-pay" && (
            <BottomSheetLoadingContent
              title="Converting to non-instant pay"
              description="Should only take a few seconds"
            />
          )}
          {step === "disable-shift-nfc-check-failed" && (
            <DisableShiftNfcFailed
              onTryAgain={async () => {
                await disableShiftNfcCheck();
              }}
              onClose={() => {
                closeBottomSheet();
              }}
            />
          )}
        </BottomSheet>
      )}
    </>
  );
}
