import { NfcHashValidationAction } from "./api/types";

export function clockActionToReadableString(clockAction: NfcHashValidationAction) {
  return {
    [NfcHashValidationAction.CLOCK_IN]: "Clock in by scanning the NFC poster",
    [NfcHashValidationAction.CLOCK_OUT]: "Clock out by scanning the NFC poster",
    [NfcHashValidationAction.BREAK_START]: "Start a break by scanning the NFC poster",
    [NfcHashValidationAction.BREAK_END]: "End a break by scanning the NFC poster",
    [NfcHashValidationAction.SKIP_BREAK]: "Skip a break by scanning the NFC poster",
  }[clockAction];
}

export function clockActionToReadableVerb(clockAction: NfcHashValidationAction) {
  return {
    [NfcHashValidationAction.CLOCK_IN]: "clock in",
    [NfcHashValidationAction.CLOCK_OUT]: "clock out",
    [NfcHashValidationAction.BREAK_START]: "break start",
    [NfcHashValidationAction.BREAK_END]: "break end",
    [NfcHashValidationAction.SKIP_BREAK]: "break skip",
  }[clockAction];
}
